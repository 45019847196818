/* eslint-disable jsx-a11y/anchor-is-valid */
import {Grid} from '@material-ui/core'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {useParams} from 'react-router-dom'
import {BlogListView} from '../moduleView/BlogListView'
import {BlogGridView} from '../moduleView/BlogGridView'
import {SliderView} from '../moduleView/SliderView'
import {GaleryView} from '../moduleView/GaleryView'

type Props = {
  className: string
  data: any
  pNumber: number
}

const useStyles = makeStyles({
  root: {
    width: 300,
    height: 650,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  MainClass: {
    display: 'flex',
    justifyContent: 'end',
  },
  title: {
    fontSize: 14,
  },
  topBar: {
    width: '100%',
    height: 50,
    backgroundColor: 'aliceblue',
    position: 'sticky',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    zIndex: 102,
  },
  pos: {
    marginBottom: 12,
  },
  blogList: {
    width: 75,
  },

  blogGrid: {
    width: '90%',
    borderRadius: 10,
  },
})

const ScreenDetails: React.FC<Props> = ({pNumber, data}: Props) => {
  const classes = useStyles()
  const {id} = useParams()
  const tempdata = data.filter((u: any) => u.id === id)
  const tempScreen = tempdata[0].appScreens.filter((pagenum: any) => pagenum.pageNumber === pNumber)

  return (
    <Grid container item xs={12} justifyContent='center'>
      <Grid item xs={4} key={pNumber} className={classes.MainClass}>
        <Card className={classes.root} style={{background: data[0].appTheme.background}}>
          <CardContent style={{padding: 0}}>
            <Grid item className={classes.topBar}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                {tempScreen[0].title}
              </Typography>
            </Grid>
            {tempScreen[0].modules?.map((module: any) => (
              <Grid item key={module.orderNo} style={{padding: 16}}>
                {module.moduleType === 'textArea' && (
                  <Typography
                    variant='h5'
                    component='h2'
                    style={{
                      color: module.moduleType === 'textArea' ? data[0].appTheme?.primary : 'black',
                    }}
                  >
                    {module.moduleInput}
                  </Typography>
                )}
                {module.moduleType === 'Blog' && module.moduleView === 'List' && (
                  <>
                    <BlogListView />
                    <BlogListView />
                    <BlogListView />
                    <BlogListView />
                    <BlogListView />
                    <BlogListView />
                    <BlogListView />
                    <BlogListView />
                  </>
                )}
                {module.moduleType === 'Blog' && module.moduleView === 'Grid' && (
                  <>
                    <Grid container item xs={12}>
                      <Grid item xs={6} style={{padding: 8}}>
                        <BlogGridView />
                      </Grid>
                      <Grid item xs={6} style={{padding: 8}}>
                        <BlogGridView />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={6} style={{padding: 8}}>
                        <BlogGridView />
                      </Grid>
                      <Grid item xs={6} style={{padding: 8}}>
                        <BlogGridView />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={6} style={{padding: 8}}>
                        <BlogGridView />
                      </Grid>
                      <Grid item xs={6} style={{padding: 8}}>
                        <BlogGridView />
                      </Grid>
                    </Grid>
                  </>
                )}
                {module.moduleType === 'Slider' && <SliderView />}
                {module.moduleType === 'Galery' && <GaleryView />}
                <Typography variant='body2' component='p' style={{marginTop: 16}}>
                  {module.showMore ? (
                    <Button variant='outlined' style={{background: 'white'}}>
                      <Typography
                        variant='body2'
                        component='p'
                        style={{color: data[0].appTheme.primary}}
                      >
                        {module.showMore ? 'More Button' : ''}
                      </Typography>
                    </Button>
                  ) : (
                    ''
                  )}
                </Typography>
              </Grid>
            ))}
            <Grid item className={classes.topBar}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                TopBar
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export {ScreenDetails}
