/* eslint-disable jsx-a11y/anchor-is-valid */
import {IAppOptions, StepProps} from '../IAppModels'
import React from 'react'
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
)

const Step6 = ({data, updateData}: StepProps) => {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: Boolean) => {
    // On Board
    if (event.target.name === IAppOptions.onBoard && value) {
      const tempAppOptions = data.appOptions?.filter((option) => option !== IAppOptions.onBoard)
      updateData({
        appOptions: [...tempAppOptions, IAppOptions.onBoard],
      })
    } else if (event.target.name === IAppOptions.onBoard && !value) {
      const tempAppOptions = data.appOptions?.filter((option) => option !== IAppOptions.onBoard)
      updateData({
        appOptions: [...tempAppOptions],
      })
    }
    // Mandatory Login
    if (event.target.name === IAppOptions.mandatoryLogin && value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.mandatoryLogin
      )

      updateData({
        appOptions: [...tempAppOptions, IAppOptions.mandatoryLogin],
      })
    } else if (event.target.name === IAppOptions.onBoard && !value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.mandatoryLogin
      )
      updateData({
        appOptions: [...tempAppOptions],
      })
    }
    // Dark Mode
    if (event.target.name === IAppOptions.darkMode && value) {
      const tempAppOptions = data.appOptions?.filter((option) => option !== IAppOptions.darkMode)
      updateData({
        appOptions: [...tempAppOptions, IAppOptions.darkMode],
      })
    } else if (event.target.name === IAppOptions.darkMode && !value) {
      const tempAppOptions = data.appOptions?.filter((option) => option !== IAppOptions.darkMode)
      updateData({
        appOptions: [...tempAppOptions],
      })
    }
    // Active In App Purchase
    if (event.target.name === IAppOptions.activeInAppPurchase && value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.activeInAppPurchase
      )
      updateData({
        appOptions: [...tempAppOptions, IAppOptions.activeInAppPurchase],
      })
    } else if (event.target.name === IAppOptions.activeInAppPurchase && !value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.activeInAppPurchase
      )
      updateData({
        appOptions: [...tempAppOptions],
      })
    }
    // Active Firebase
    if (event.target.name === IAppOptions.activeFirebase && value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.activeFirebase
      )
      updateData({
        appOptions: [...tempAppOptions, IAppOptions.activeFirebase],
      })
    } else if (event.target.name === IAppOptions.activeFirebase && !value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.activeFirebase
      )
      updateData({
        appOptions: [...tempAppOptions],
      })
    }
    // Active Multilanguage
    if (event.target.name === IAppOptions.activeMultiLanguage && value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.activeMultiLanguage
      )
      updateData({
        appOptions: [...tempAppOptions, IAppOptions.activeMultiLanguage],
      })
    } else if (event.target.name === IAppOptions.activeMultiLanguage && !value) {
      const tempAppOptions = data.appOptions?.filter(
        (option) => option !== IAppOptions.activeMultiLanguage
      )
      updateData({
        appOptions: [...tempAppOptions],
      })
    }
  }

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/* begin::Label */}
        <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
          <span className='required'>Select Options</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your apps framework'
          ></i>
        </label>
        {/* end::Label */}
        <div className={classes.root}>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend'>App Options</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, v) => {
                      handleChange(e, v)
                    }}
                    name='onBoard'
                  />
                }
                label='On board'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, v) => {
                      handleChange(e, v)
                    }}
                    name='mandatoryLogin'
                  />
                }
                label='Mandatory Login'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, v) => {
                      handleChange(e, v)
                    }}
                    name='darkMode'
                  />
                }
                label='Dark Mode'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, v) => {
                      handleChange(e, v)
                    }}
                    name='activeInAppPurchase'
                  />
                }
                label='Active In App Purchase'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, v) => {
                      handleChange(e, v)
                    }}
                    name='activeFirebase'
                  />
                }
                label='Active Firebase'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, v) => {
                      handleChange(e, v)
                    }}
                    name='activeMultilanguage'
                  />
                }
                label='Multilanguage'
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </div>
  )
}
export default Step6
