/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {CreateAppModal, HeaderUserMenu} from '../../../partials'

type Props = {
  data: any
}

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC<Props> = ({data}: Props) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(
    data?.length > 0 ? false : true
  )

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::Actions */}
      <div className='d-flex align-items-center py-1'>
        {/* begin::Button */}

        <a
          className='btn btn-sm btn-primary cursor-pointer'
          id='kt_toolbar_primary_button'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_create_app'
          onClick={() => setShowCreateAppModal(true)}
        >
          Create New App
        </a>
        {/* end::Button */}
      </div>
      {/* end::Actions */}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        appData={data}
      />
    </div>
  )
}

export {Topbar}
