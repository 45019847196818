/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AppDetails} from '../../../_metronic/partials/widgets/app-details'

type Props = {
  data: any
  pNumber: number
}

const AppPage: FC<Props> = ({data, pNumber}: Props) => (
  <>
    <AppDetails className='card-xxl-stretch mb-5 mb-xxl-8' data={data} pNumber={pNumber} />
  </>
)

const AppBuilder: FC<Props> = ({data, pNumber}: Props) => {
  return (
    <>
      <AppPage data={data} pNumber={pNumber} />
    </>
  )
}

export {AppBuilder}
