import {FC, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {ThemeModeProvider} from '../partials'
import {MenuComponent} from '../assets/ts/components'

type Props = {
  data: any
}

const MasterLayout: FC<Props> = ({data}: Props) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid' id='kt_wrapper'>
          <HeaderWrapper data={data} />
          <div className='wrapper d-flex flex-column flex-row-fluid'>
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              {location.pathname === '/dashboard' ? null : <Toolbar data={data} />}
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
