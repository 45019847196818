/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useState} from 'react'
import {CreateAppModal} from '../../../partials'
import {useLayout} from '../../core'
import {AsideDefault} from '../aside/AsideDefault'

type Props = {
  data: any
}
const Toolbar1: FC<Props> = ({data}: Props) => {
  const {classes} = useLayout()
  const [showMenu, setShowMenu] = useState<boolean>(true)
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  return (
    <>
      <div className='toolbar' id='kt_toolbar' style={{zIndex: 103}}>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
          style={{justifyContent: 'end'}}
        >
          {showMenu && <AsideDefault data={data} setShowMenu={setShowMenu} />}
        </div>
        {/* end::Container */}
      </div>
      <CreateAppModal
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        appData={data}
      />
    </>
  )
}

export {Toolbar1}
