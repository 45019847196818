import React, {FC} from 'react'
import {useLayout} from '../../core/LayoutProvider'
import {Toolbar1} from './Toolbar1'
type Props = {
  data: any
}
const Toolbar: FC<Props> = ({data}: Props) => {
  const {config} = useLayout()

  switch (config.toolbar.layout) {
    case 'toolbar1':
      return (
        <div>
          <Toolbar1 data={data} />
        </div>
      )

    default:
      return <Toolbar1 data={data} />
  }
}

export {Toolbar}
