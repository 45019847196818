import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {AppBuilder} from '../pages/app-builder'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ScreenBuilder} from '../pages/screenBuilder'

// App Config

// mandatoryLogin = false
// darkMode = false
// activeInAppPurchase = false
// activeFirebase = false
// activeMultiLanguage = false
// revenuecatEntegrationKey: String?

// Onboarding

// struct AppOnboardingModel {
//   image: String?
//   title: String?
//   description: String?
//   buttonText: String?
//   order: Int = 1
// }

// Color Palet

// struct AppThemesModel {
//   lightprimary: String = "#f28482"
//   lightprimaryVariant: String = "#84a59d"
//   lightonPrimary: String = "#FFFFFF"
//   lightsecondary: String = "#03dac6"
//   lightsecondaryVariant: String = "#018786"
//   lightonSecondary: String = "#000000"
//   lighterror: String = "#b00020"
//   lightonError: String = "#FFFFFF"
//   lightsurface: String = "#FFFFFF"
//   lightonSurface: String = "#000000"
//   lightbackground: String = "#f4f4f4"
//   lightonBackground: String = "#000000"
//   lightgrayText: String = "#FFFFFF"

//   darkprimary: String = "#f28482"
//   darkprimaryVariant: String = "#84a59d"
//   darkonPrimary: String = "#FFFFFF"
//   darksecondary: String = "#03dac6"
//   darksecondaryVariant: String = "#018786"
//   darkonSecondary: String = "#000000"
//   darkerror: String = "#b00020"
//   darkonError: String = "#000000"
//   darksurface: String = "#000000"
//   darkonSurface: String = "#FFFFFF"
//   darkbackground: String = "#121212"
//   darkonBackground: String = "#FFFFFF"
//   darkgrayText: String = "#000000"
// }

// App Typography

// struct AppTypographyModel {
//   largeTitle: Int?
//   title1: Int?
//   title2: Int?
//   title3: Int?
//   headline: UInt?
//   body: Int?
//   callout: Int?
//   subheadline: Int?
//   footnote: Int?
//   caption1: Int?
//   caption2: Int?
// }

// App Module: hepsi true false seçilebilir olabilir -> checkbox

// enum Modules {
//   case audio
//   case reader
//   case blog
//   case share
//   case instaStory
//   case pushNotification
// }

let data = [
  {
    id: '1',
    appName: "Modmob's App",
    appTemplate: 'kitUp',
    status: 'In Progress',
    appOnboarding: {
      title: "Modmob's App title",
      subtitle: "Modmob's App Subtitle",
      nextButtonText: 'İlerle',
    },
    appConfig: {
      hasOnboarding: false,
      mandatoryLogin: false,
      darkMode: false,
      activeInAppPurchase: false,
      activeFirebase: false,
      activeMultilanguage: false,
    },
    appScreens: [
      {
        pageNumber: '1',
        title: 'Home',
        modules: [
          {
            orderNo: '1',
            moduleType: 'Blog',
            moduleView: 'List',
          },
          {
            orderNo: '2',
            moduleType: 'Slider',
            moduleView: 'inline-4',
          },
          {
            norderNo: '3',
            moduleType: 'Galery',
            moduleView: 'grid-4',
            showMore: true,
          },
        ],
      },
      {
        pageNumber: '2',
        title: 'About',
        modules: [
          {
            orderNo: '1',
            moduleType: 'Galery',
            moduleView: 'inline-2',
            showMore: true,
          },
          {
            orderNo: '2',
            moduleType: 'textArea',
            moduleInput:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          },
        ],
      },
      {
        pageNumber: '3',
        title: 'Blogs',
        modules: [
          {
            orderNo: '1',
            moduleType: 'Slider',
            moduleView: 'inline-4',
          },
          {
            orderNo: '2',
            moduleType: 'Blog',
            moduleView: 'List',
          },
        ],
      },
    ],
    appTheme: {
      primary: 'blue',
      primaryVariant: '#dcdd5560',
      secondary: 'black',
      background: 'gray',
      backgroundColor: 'red',
    },
  },
  {
    id: '2',
    appName: "Modmob's App 2",
    appTemplate: 'kitUp',
    status: 'In Progress',
    appOnboarding: {
      title: "Modmob's App title 2",
      subtitle: "Modmob's App Subtitle",
      nextButtonText: 'İlerle',
    },
    appConfig: {
      hasOnboarding: false,
      mandatoryLogin: false,
      darkMode: false,
      activeInAppPurchase: false,
      activeFirebase: false,
      activeMultilanguage: false,
    },
    appScreens: [
      {
        pageNumber: '1',
        title: 'Home2',
        modules: [],
      },
      {
        pageNumber: '2',
        title: 'About',
        modules: [],
      },
      {
        pageNumber: '3',
        title: 'Blogs',
        modules: [],
      },
    ],
    appTheme: {
      primary: 'blue',
      primaryVariant: '#dcdd5560',
      secondary: 'black',
      background: '#fff',
      backgroundColor: 'red',
    },
  },
]

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout data={data} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper data={data} />} />
        <Route path='dashboard/:id' element={<AppBuilder data={data} pNumber={0} />} />
        <Route
          path='dashboard/:id/:pageNumber'
          element={<ScreenBuilder data={data} pNumber={0} />}
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
