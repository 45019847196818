/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {TablesWidget5} from '../../../_metronic/partials/widgets'

type Props = {
  data: any
}

const DashboardPage: FC<Props> = ({data}: Props) => (
  <>
    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' data={data} />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC<Props> = ({data}: Props) => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage data={data} />
    </>
  )
}

export {DashboardWrapper}
