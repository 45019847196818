import React, {FC} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ThemeMenu from './ThemeMenu'
import ScreenEditor from './ScreenEditor'
import PageEditor from './PageEditor'
import FinishBuilder from './PublishEditor'
import {useParams} from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

type Props = {
  data: any
  setShowMenu: (value: boolean) => void
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{height: '100%'}} p={3}>
          <Typography style={{height: '100%'}}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    width: 0,
    padding: 0,
  },
  empty: {},
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const AsideMenu: FC<Props> = ({data, setShowMenu}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const {id} = useParams()

  const appData = data.filter((u: any) => u.id === id)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        aria-label='Vertical tabs example'
        className={classes.tabs}
      >
        <Tab label='App Theme' {...a11yProps(0)} />
        <Tab disabled label='Screen Select' {...a11yProps(1)} />
        <Tab disabled label='Item Three' {...a11yProps(2)} />
        <Tab disabled label='Item Four' {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ThemeMenu data={appData} index={0} setValue={setValue} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ScreenEditor data={appData} index={1} setValue={setValue} expectedId={id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PageEditor data={appData} index={2} setValue={setValue} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <FinishBuilder data={appData} index={3} setValue={setValue} setShowMenu={setShowMenu} />
      </TabPanel>
    </div>
  )
}
export default AsideMenu
