export interface IAppBasic {
  appName: string
  appTheme: 'kitUp' | 'bidiyet' | 'blogApp'
}

export type TAppFramework = 'IOS' | 'Android'

export enum IAppOptions {
  onBoard = 'onBoard',
  mandatoryLogin = 'mandatoryLogin',
  darkMode = 'darkMode',
  activeInAppPurchase = 'activeInAppPurchase',
  activeFirebase = 'activeFirebase',
  activeMultiLanguage = 'activeMultilanguage',
}

export interface IAppDatabase {
  databaseName: string
  databaseSolution: 'MySQL' | 'Firebase' | 'DynamoDB'
}

export type TAppStorage = 'Basic Server' | 'AWS' | 'Google'

export interface ICreateAppData {
  appBasic: IAppBasic
  appFramework: TAppFramework
  appDatabase: IAppDatabase
  appStorage: TAppStorage
  appOptions: IAppOptions[]
}

export const defaultCreateAppData: ICreateAppData = {
  appBasic: {appName: '', appTheme: 'kitUp'},
  appFramework: 'IOS',
  appDatabase: {databaseName: 'db_name', databaseSolution: 'MySQL'},
  appStorage: 'Basic Server',
  appOptions: [],
}

export type StepProps = {
  data: ICreateAppData
  updateData: (fieldsToUpdate: Partial<ICreateAppData>) => void
  hasError: boolean
}
