import {Button, FormControl, Grid, MenuItem, Select, Typography} from '@material-ui/core'
import React, {FC} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const ModOpt = [
  {
    id: 'Blog',
    styles: [
      {
        styleNo: '1',
        styleName: 'Grid',
      },
      {
        styleNo: '2',
        styleName: 'List',
      },
    ],
  },
  {
    id: 'Slider',
    styles: [
      {
        styleNo: '1',
        styleName: 'inline-4',
      },
      {
        styleNo: '2',
        styleName: 'List',
      },
    ],
  },

  {
    id: 'Galery',
    styles: [
      {
        styleNo: '1',
        styleName: 'Grid-4',
      },
      {
        styleNo: '2',
        styleName: 'List-4',
      },
    ],
  },
]

type Props = {
  data: any
  index: number
  setValue: (value: number) => void
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    header: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },
    next: {display: 'flex', alignItems: 'end', justifyContent: 'end'},
    back: {display: 'flex', alignItems: 'end', justifyContent: 'start'},
  })
)

const PageEditor: FC<Props> = ({data, setValue, index}) => {
  const classes = useStyles()

  const navigate = useNavigate()

  //dropdown options for modules
  const [modulOptions, setModuleOptions] = React.useState('')
  const [styleOptions, setStyleOptions] = React.useState('')

  //handle dropdown selection

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setModuleOptions(event.target.value as string)
  }
  const handleStyleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setStyleOptions(event.target.value as string)
  }

  //getting the right page for the right app

  const {id, pageNumber} = useParams()
  const selectedApp = data.filter((u: any) => u.id === id)
  const selectedPage = selectedApp[0].appScreens.filter(
    (page: any) => page.pageNumber === pageNumber
  )

  //next and back buttons
  const handleButton = () => {
    setValue(1)
  }

  //adding module into app

  const handleAddModule = () => {
    const order = selectedPage[0].modules.length + 1
    const tempModule = {
      orderNo: order,
      moduleType: modulOptions,
      moduleView: styleOptions,
    }
    // data[0].appScreens
    //   .filter((addedModule: any) => addedModule.pageNumber === pageNumber)
    //   .modules.push(tempModule)
    selectedPage[0].modules.push(tempModule)
    setModuleOptions('')
    setStyleOptions('')
    navigate(`/dashboard/${id}/${pageNumber}`, {state: {data: data, pNumber: pageNumber}})
  }
  return (
    <Grid container item xs={12} style={{width: 350, height: '100%'}}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant='h3' style={{marginBottom: 16}}>
          Editing {selectedPage[0].title}
        </Typography>
        <Grid
          item
          xs={12}
          style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
        >
          <Typography id='demo-simple-select-label'>Select a Module</Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={modulOptions}
              onChange={handleChange}
            >
              {ModOpt.map((u: any) => (
                <MenuItem value={u.id}>{u.id}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
        >
          {modulOptions && (
            <>
              <Typography id='demo-simple-select-label'>Select the Style:</Typography>
              <FormControl className={classes.formControl}>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={styleOptions}
                  onChange={handleStyleChange}
                >
                  {ModOpt.filter((ul: any) => ul.id === modulOptions)[0].styles.map((u: any) => (
                    <MenuItem value={u.styleName}>{u.styleName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
        >
          <Button
            variant='outlined'
            disabled={modulOptions ? false : true}
            onClick={() => handleAddModule()}
          >
            Add Module
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
        <Grid item className={classes.next}>
          <Button variant='contained' onClick={() => handleButton()}>
            Edit New Page
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
        {index !== 0 && (
          <Grid item className={classes.back}>
            <Button variant='contained' onClick={() => setValue(index - 1)}>
              Back
            </Button>
          </Grid>
        )}
        <Grid item className={classes.next}>
          <Button variant='contained' onClick={() => setValue(index + 1)}>
            Save & Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default PageEditor
