import {Button, Grid, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import React, {FC} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
type Props = {
  data: any
  index: number
  setValue: (value: number) => void
  setShowMenu: (value: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },
    next: {display: 'flex', alignItems: 'end', justifyContent: 'end'},
    back: {display: 'flex', alignItems: 'end', justifyContent: 'start'},
  })
)

const FinishBuilder: FC<Props> = ({data, index, setValue, setShowMenu}) => {
  const {id, pageNumber} = useParams()
  const classes = useStyles()
  const navigate = useNavigate()

  const handleButton = (id: any, pageNum: any) => {
    setValue(0)
    setShowMenu(false)
    navigate(`/dashboard/${id}`, {state: {data: data, pNumber: pageNum}})
  }
  return (
    <>
      <Grid container item xs={12} style={{width: 350, height: '100%'}}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant='h3' style={{marginBottom: 16}}>
            Publish your App
          </Typography>
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
          {index !== 0 && (
            <Grid item className={classes.back}>
              <Button variant='contained' onClick={() => setValue(index - 1)}>
                Back
              </Button>
            </Grid>
          )}
          <Grid item className={classes.next}>
            <Button variant='contained' onClick={() => handleButton(id, pageNumber)}>
              Finish
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default FinishBuilder
