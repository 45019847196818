import {Button, FormControl, Grid, MenuItem, Select, TextField, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import React, {FC, useState} from 'react'
import {useNavigate} from 'react-router-dom'
type Props = {
  data: any
  index: number
  setValue: (value: number) => void
  expectedId: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    header: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },
    next: {display: 'flex', alignItems: 'end', justifyContent: 'end'},
    back: {display: 'flex', alignItems: 'end', justifyContent: 'start'},
    edit: {display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%'},
  })
)

const ScreenEditor: FC<Props> = ({data, index, setValue, expectedId}) => {
  const classes = useStyles()
  const [pageNumber, setPageNumber] = React.useState('')
  const navigate = useNavigate()
  const [tempPageName, setTempPageName] = useState('')
  const [showPageName, setShowPageName] = useState(false)

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setPageNumber(event.target.value as string)
    setShowPageName(true)
  }

  const handleButton = (id: any, pageNumber: any) => {
    setValue(index + 1)
    navigate(`/dashboard/${id}/${pageNumber}`, {state: {data: data, pNumber: pageNumber}})
  }
  const handleNewPageName = () => {
    data[0].appScreens.filter((u: any) => u.pageNumber === pageNumber)[0].title = tempPageName
    setTempPageName('')
    setShowPageName(false)
  }
  return (
    <>
      <Grid container item xs={12} style={{width: 350, height: '100%'}}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant='h3' style={{marginBottom: 16}}>
            Select a Screen to Edit
          </Typography>
          <Grid
            item
            xs={12}
            style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
          >
            <Typography id='demo-simple-select-label'>Select a Screen</Typography>
            <FormControl className={classes.formControl}>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={pageNumber}
                onChange={handleChange}
              >
                {data[0].appScreens.map((u: any) => (
                  <MenuItem value={u.pageNumber}>{u.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {showPageName && (
            <Grid className={classes.edit}>
              <Grid item xs={12}>
                <form className={classes.root} noValidate autoComplete='off'>
                  <TextField
                    id='standard-basic'
                    label='Enter New Page Name'
                    onChange={(e) => setTempPageName(e.target.value)}
                  />
                  <Button
                    variant='contained'
                    style={{height: 32, width: 'fit-content'}}
                    onClick={handleNewPageName}
                  >
                    Save
                  </Button>
                </form>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
          {index !== 0 && (
            <Grid item className={classes.back}>
              <Button variant='contained' onClick={() => setValue(index - 1)}>
                Back
              </Button>
            </Grid>
          )}
          <Grid item className={classes.next}>
            <Button
              variant='contained'
              disabled={pageNumber ? false : true}
              onClick={() => handleButton(expectedId, pageNumber)}
            >
              Save & Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default ScreenEditor
