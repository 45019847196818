/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useParams} from 'react-router-dom'
import {ScreenDetails} from '../../../_metronic/partials/widgets/screen-details'

type Props = {
  data: any
  pNumber: any
}

const ScreenPage: FC<Props> = ({data, pNumber}: Props) => (
  <>
    <ScreenDetails className='card-xxl-stretch mb-5 mb-xxl-8' data={data} pNumber={pNumber} />
  </>
)

const ScreenBuilder: FC<Props> = ({data, pNumber}: Props) => {
  const {id, pageNumber} = useParams()
  const selectedApp = data.filter((u: any) => u.id === id)
  return (
    <>
      <ScreenPage data={selectedApp} pNumber={pageNumber} />
    </>
  )
}

export {ScreenBuilder}
