/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

type Props = {
  className: string
  data: any
}

const TablesWidget5: React.FC<Props> = ({className, data}: Props) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Your Apps</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            You can see the list of your applications below
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data?.map((ddata: any) => (
                    <tr key={ddata.id}>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {ddata.appOnboarding?.title}
                        </a>
                        <span className='text-muted fw-semibold d-block'>
                          {ddata.appOnboarding?.subtitle}
                        </span>
                      </td>
                      <td className='text-end text-muted fw-semibold'>{ddata.appTemplate}</td>
                      <td className='text-end'>
                        <span style={{color: ddata.appTheme?.primary}} className='badge'>
                          Primary Text Color
                        </span>
                      </td>
                      <td className='text-end'>
                        <span style={{color: ddata.appTheme?.secondary}} className='badge'>
                          Secondary Text Color
                        </span>
                      </td>
                      <td className='text-end text-muted fw-semibold'>{ddata.status}</td>
                      <td className='text-end'>
                        <Link
                          to={`/dashboard/${ddata.id}`}
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <ArrowForwardIcon />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget5}
