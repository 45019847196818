/* eslint-disable jsx-a11y/anchor-is-valid */
import {Grid} from '@material-ui/core'
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  data: any
  pNumber: number
}

const useStyles = makeStyles({
  root: {
    width: 250,
    height: 600,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  MainClass: {
    display: 'flex',
    justifyContent: 'end',
  },
  title: {
    fontSize: 14,
  },
  topBar: {
    width: '100%',
    height: 50,
    backgroundColor: 'aliceblue',
    position: 'sticky',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    zIndex: 102,
  },
  pos: {
    marginBottom: 12,
  },
})

const AppDetails: React.FC<Props> = ({className, data, pNumber}: Props) => {
  const classes = useStyles()
  const {id} = useParams()
  const selectedApp = data.filter((u: any) => id === u.id)

  return (
    <Grid container item xs={12}>
      {selectedApp[0].appScreens?.map((ddata: any) => (
        <Grid item xs={4} key={ddata.id} className={classes.MainClass}>
          <Card className={classes.root} style={{background: selectedApp[0].appTheme.background}}>
            <CardContent style={{padding: 0}}>
              <Grid item className={classes.topBar}>
                <Typography className={classes.title} color='textSecondary' gutterBottom>
                  {ddata?.title}
                </Typography>
              </Grid>
              {ddata.modules?.map((module: any) => (
                <Grid item style={{padding: 16}}>
                  {module.moduleType === 'textArea' && (
                    <Typography
                      variant='h5'
                      component='h2'
                      style={{
                        color:
                          module.moduleType === 'textArea'
                            ? selectedApp[0].appTheme?.primary
                            : 'black',
                      }}
                    >
                      {module.moduleInput}
                    </Typography>
                  )}
                  {module.moduleType === 'Blog' && module.moduleView === 'List' && (
                    <img
                      style={{width: '100%'}}
                      src={toAbsoluteUrl('/media/modules/blog/listBlog.png')}
                      alt='metronic'
                    />
                  )}
                  {module.moduleType === 'Blog' && module.moduleView === 'Grid' && (
                    <img
                      style={{width: '100%'}}
                      src={toAbsoluteUrl('/media/modules/blog/gridBlog.png')}
                      alt='metronic'
                    />
                  )}
                  {module.moduleType === 'Slider' && (
                    <img
                      style={{width: '100%'}}
                      src={toAbsoluteUrl('/media/modules/slider/Slider.png')}
                      alt='metronic'
                    />
                  )}
                  {module.moduleType === 'Galery' && (
                    <img
                      style={{width: '100%'}}
                      src={toAbsoluteUrl('/media/modules/gallery/gallery.png')}
                      alt='metronic'
                    />
                  )}
                  <Typography variant='body2' component='p' style={{marginTop: 16}}>
                    {module.showMore ? (
                      <Button variant='outlined' style={{background: 'white'}}>
                        <Typography
                          variant='body2'
                          component='p'
                          style={{color: selectedApp[0].appTheme.primary}}
                        >
                          {module.showMore ? 'More Button' : ''}
                        </Typography>
                      </Button>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Grid>
              ))}
              <Grid item className={classes.topBar}>
                <Typography className={classes.title} color='textSecondary' gutterBottom>
                  TopBar
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export {AppDetails}
