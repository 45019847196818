/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import AsideMenu from './AsideMenu'

type Props = {
  data: any
  setShowMenu: (value: boolean) => void
}

const AsideDefault: FC<Props> = ({data, setShowMenu}) => {
  const {classes} = useLayout()
  const asideRef = useRef<HTMLDivElement | null>(null)
  classes.aside = classes.aside.filter((item) => item !== 'aside-hoverable')

  return (
    <div
      id='kt_aside'
      className={clsx('aside')}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      ref={asideRef}
      style={{marginTop: 65}}
    >
      {/* begin::Aside menu */}

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu data={data} setShowMenu={setShowMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  )
}

export {AsideDefault}
