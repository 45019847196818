import {Card, Grid} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import React from 'react'

const BlogGridView = () => {
  return (
    <Card
      style={{
        borderRadius: 10,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Grid container item xs={12}>
        <Skeleton variant='rect' width={'100%'} height={90} />
      </Grid>
      <Skeleton variant='text' width={90} height={20} />
      <Skeleton variant='text' width={70} />
    </Card>
  )
}
export {BlogGridView}
