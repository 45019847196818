import {Grid, makeStyles} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import React from 'react'

const useStyles = makeStyles({
  blogList: {
    width: '100%',
  },
})
const GaleryView = () => {
  const classes = useStyles()
  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={6} style={{padding: 8}}>
          <Skeleton variant='rect' height={120} className={classes.blogList} animation='wave' />
        </Grid>
        <Grid item xs={6} style={{padding: 8}}>
          <Skeleton variant='rect' height={120} className={classes.blogList} animation='wave' />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} style={{padding: 8}}>
          <Skeleton variant='rect' height={120} className={classes.blogList} animation='wave' />
        </Grid>
        <Grid item xs={6} style={{padding: 8}}>
          <Skeleton variant='rect' height={120} className={classes.blogList} animation='wave' />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6} style={{padding: 8}}>
          <Skeleton variant='rect' height={120} className={classes.blogList} animation='wave' />
        </Grid>
        <Grid item xs={6} style={{padding: 8}}>
          <Skeleton variant='rect' height={120} className={classes.blogList} animation='wave' />
        </Grid>
      </Grid>
    </>
  )
}
export {GaleryView}
