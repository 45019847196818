import {Grid, makeStyles} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import React from 'react'

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
    padding: 8,
  },

  blogList: {
    width: 150,
  },
})
const SliderView = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.root}>
      <Grid container item xs={12} style={{minWidth: 'max-content'}}>
        <Skeleton
          variant='rect'
          height={150}
          style={{marginRight: 8, marginLeft: 8}}
          className={classes.blogList}
          animation='wave'
        />
        <Skeleton
          variant='rect'
          style={{marginRight: 8}}
          height={150}
          className={classes.blogList}
          animation='wave'
        />
        <Skeleton
          variant='rect'
          style={{marginRight: 8}}
          height={150}
          className={classes.blogList}
          animation='wave'
        />
        <Skeleton
          variant='rect'
          style={{marginRight: 8}}
          height={150}
          className={classes.blogList}
          animation='wave'
        />
        <Skeleton
          variant='rect'
          style={{marginRight: 8}}
          height={150}
          className={classes.blogList}
          animation='wave'
        />
        <Skeleton
          variant='rect'
          style={{marginRight: 8}}
          height={150}
          className={classes.blogList}
          animation='wave'
        />
      </Grid>
    </Grid>
  )
}
export {SliderView}
