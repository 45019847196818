import {Button, Grid, Typography} from '@material-ui/core'
import React, {FC, useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'

type Props = {
  data: any
  index: number
  setValue: (value: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
    },
    header: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
    },
    edit: {display: 'flex', justifyContent: 'space-between', alignItems: 'start', width: '100%'},
    next: {display: 'flex', alignItems: 'end', justifyContent: 'end'},
  })
)

const ThemeMenu: FC<Props> = ({data, index, setValue}) => {
  const classes = useStyles()
  const [newAppName, setNewAppName] = useState(data[0].appName)
  const [tempAppName, setTempAppName] = useState('')
  const [editNameSelector, setEditNameSelector] = useState(false)

  const handleNameSave = () => {
    setNewAppName(tempAppName)
    data[0].appName = newAppName
    setEditNameSelector(!editNameSelector)
  }
  return (
    <Grid container item xs={12} style={{maxWidth: 350, height: '100%'}}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant='h3' style={{marginBottom: 16}}>
          Edit Theme
        </Typography>
        <Grid className={classes.edit}>
          <Typography variant='h6'>
            <span style={{fontWeight: 'bold'}}>App Name:</span> {newAppName}
          </Typography>
          <EditIcon
            color='primary'
            fontSize='large'
            onClick={() => setEditNameSelector(!editNameSelector)}
            style={{cursor: 'pointer'}}
          />
        </Grid>
        {editNameSelector && (
          <Grid item xs={12}>
            <form className={classes.root} noValidate autoComplete='off'>
              <TextField
                id='standard-basic'
                defaultValue={data[0].appName}
                onChange={(e) => setTempAppName(e.target.value)}
              />
              <Button
                variant='contained'
                style={{height: 32, width: 'fit-content'}}
                onClick={handleNameSave}
              >
                Save
              </Button>
            </form>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} className={classes.next}>
        <Button variant='contained' onClick={() => setValue(index + 1)}>
          Save & Next
        </Button>
      </Grid>
    </Grid>
  )
}
export default ThemeMenu
