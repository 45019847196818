import {Card, Grid, makeStyles} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import React from 'react'

const useStyles = makeStyles({
  blogList: {
    width: 75,
  },
})
const BlogListView = () => {
  const classes = useStyles()
  return (
    <Card
      style={{
        borderRadius: 10,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Skeleton variant='rect' height={90} className={classes.blogList} animation='wave' />
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Skeleton variant='text' width={150} />
          <Skeleton variant='text' width={90} />
          <Skeleton variant='text' width={150} height={50} />
        </Grid>
      </Grid>
    </Card>
  )
}
export {BlogListView}
